// controllers/camera_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video", "canvas"];
  stream = null;

  connect() {
    console.log("Camera controller connected");

    // Detectar si el usuario está navegando dentro de Instagram
    if (
      /Instagram/.test(navigator.userAgent) || 
      /FBAV/.test(navigator.userAgent) ||
      /InstagramApp/.test(navigator.userAgent)
    ) {
      alert("Por favor, abrí esta página en un navegador completo como Chrome o Firefox :3");
      return; // No continuar con el acceso a la cámara
    }

    this.startCamera();
  }

  startCamera() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia API no está disponible en este navegador.");
      alert("getUserMedia API no está disponible en este navegador.");
      return;
    }

    console.log("Solicitando acceso a la cámara...");
    
    // Acceder a la cámara frontal
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } })
      .then((stream) => {
        this.stream = stream; // Guardamos el stream para detenerlo después
        this.videoTarget.srcObject = stream;

        this.videoTarget.addEventListener("loadedmetadata", () => {
          console.log("Video metadata cargada: ", {
            width: this.videoTarget.videoWidth,
            height: this.videoTarget.videoHeight
          });
          this.videoTarget.play();
          this.drawMask();
        });
      })
      .catch((error) => {
        console.error("Error al intentar acceder a la cámara: ", error);
        alert("No se pudo acceder a la cámara. Asegúrate de haber dado los permisos necesarios.");
      });
  }

  drawMask() {
    const canvas = this.canvasTarget;
    const ctx = canvas.getContext("2d");
    const video = this.videoTarget;

    if (!canvas || !ctx) {
      console.error("No se pudo acceder al contexto del canvas.");
      return;
    }

    const draw = () => {
      if (!video.videoWidth || !video.videoHeight) {
        console.warn("El video no está disponible para dibujar.");
        requestAnimationFrame(draw);
        return;
      }

      // Ajustar el tamaño del canvas al tamaño del video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      console.log("Dibujando en canvas. Dimensiones: ", {
        canvasWidth: canvas.width,
        canvasHeight: canvas.height
      });

      // Dibujar el video en el canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Crear una máscara circular en el centro
      ctx.globalCompositeOperation = 'destination-in';
      ctx.beginPath();
      const radius = Math.min(canvas.width, canvas.height) / 2.5; // Ajustar el tamaño del círculo
      ctx.arc(canvas.width / 2, canvas.height / 2, radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.globalCompositeOperation = 'source-over';

      // Actualizar el frame en cada animación
      requestAnimationFrame(draw);
    };

    console.log("Iniciando el proceso de enmascaramiento...");
    draw();
  }

  disconnect() {
    console.log("Camera controller disconnected");
    // Detener la cámara al salir de la página
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      console.log("Cámara detenida");
    }
  }
}
